.css-qcqlck-MuiDataGrid-root .MuiDataGrid-container--top [role=row] {
  background-color: #4FA9A7 !important;
  color: white !important;
  font-weight: bold !important;
  position: static !important;
}

.css-qcqlck-MuiDataGrid-root{
  position: static !important;
}


/* Table ScrollBar */
.css-1b9e9gy::-webkit-scrollbar {
  display: inline-block;
  width: 5px; /* Width of the scrollbar */
}

/* Track */
.css-1b9e9gy::-webkit-scrollbar-track {
  background: #fff; /* Color of the scrollbar track */
}

/* Handle */
.css-1b9e9gy::-webkit-scrollbar-thumb {
  background: #4FA9A7; /* Color of the scrollbar thumb */
  border-radius: 6px;
}

/* Handle on hover */
.css-1b9e9gy::-webkit-scrollbar-thumb:hover {
  background: #000; /* Color of the scrollbar thumb on hover */
}

.css-1rtad1::-webkit-scrollbar {
  display: inline-block;
  width: 5px; /* Width of the scrollbar */
}

.css-1rtad1::-webkit-scrollbar-track {
  background: 4FA9A7; /* Color of the scrollbar track */
  width: 5px; /* Width of the scrollbar */
}

.css-1rtad1::-webkit-scrollbar-thumb {
  background: #4FA9A7; /* Color of the scrollbar thumb */
  border-radius: 6px;
}
.css-1rtad1::-webkit-scrollbar-thumb:hover {
  background: #000; /* Color of the scrollbar thumb on hover */
}


.react-datepicker__input-container {
  position: static !important;
  /* display: inline-block; */
  /* width: 100%; */
  z-index: 0;
}

.css-qcqlck-MuiDataGrid-root .MuiDataGrid-container--top, .css-qcqlck-MuiDataGrid-root .MuiDataGrid-container--bottom {
  z-index: 10;
  position: static;
}