/* Override DataTable styles */
#sidebar-container .MuiDataGrid-root {
  border: 1px solid #ccc !important; /* Example custom border */
}

#sidebar-container .MuiDataGrid-cell {
  padding: 8px !important; /* Example custom padding for cells */
}

/* Override DatePicker styles */
#sidebar-container .MuiPickersDay-daySelected {
  background-color: #4caf50 !important; /* Example custom selected day background color */
}

#sidebar-container .MuiPickersDay-dayDisabled {
  color: #ccc !important; /* Example custom disabled day text color */
}


